import  { boolean, z } from 'zod';
import { SubProgrammeConfigQuery } from '~/schemas/sub-programme-config-query';
import { CRMEntity } from '~/utils/enums';
import { SubProgrammeConfiguration } from '~/classes/sub-programme-configuration';
import GibsControlLoading from '~/components/gibs/control/loading.vue';

class lookupData
{
    Key : string = "";
    Value : Array<IFormTypeAheadItem> = new Array<IFormTypeAheadItem>();
}

export const useFormsStore = defineStore( 'formsStore', () => {
    //Props
    const subProgrammeConfig = ref<typeof SubProgrammeConfigQuery | null>(null);
    const programmeGroupName = ref('');
    let currentSelectedSection = ref<IFormSection | null>(null);
    const subProgrammeConfiguration = ref<SubProgrammeConfiguration | null>(null);
    let formTypeAheadItems = ref<Array<lookupData>>(new Array<lookupData>());
    const ModalCollection = ref<{ [key: string]: z.infer<typeof FieldInfo> }>({});
    const CurrentDocuments = ref<Array<String>>([]);
    const PaymentDocuments = ref<Array<String>>([]);
    const busySaving = ref<Boolean>(false);
    var apiCallDone = ref<boolean>(false); 
    var feeIsPaid = ref<boolean>(false); 
    var POPUploaded = ref<boolean>(false); 
    var paymentDocsFetched = ref<boolean>(false);
    //var isQualificationsExists = ref<boolean>(false);
    var isBackgroundExists = ref<boolean>(false); 
    const globalEnrolmentId = ref<string>('');
    // const requests = ref<Array<IRequest>>(new Array<IRequest>());

    const loader = ref<typeof GibsControlLoading>();
    //Getters / Setters
    const getSubProgrammeConfig = computed(
        () => {
            return subProgrammeConfig.value;
        }
    );
    const ProgrammeName = computed({
        get () {
            return programmeGroupName.value;
        },
        set (input: string) {
            programmeGroupName.value = input;
        }
    });
    const SelectedFormSection = computed({
        get () {
            return currentSelectedSection.value;
        },
        set (input: IFormSection | null) {
            if (typeof(input) !== 'undefined' && input != null) {
                currentSelectedSection.value = input;
            }
        }
    });
    const SubProgrammeConfig = computed({
        get () {
            return subProgrammeConfiguration.value as SubProgrammeConfiguration;
        },
        set (input: SubProgrammeConfiguration) {
            subProgrammeConfiguration.value = input;
        }
    });

    const getPaymentStatusChecked = computed(
        () => {
            return apiCallDone.value;
        }
    );

    function setPaymentStatusChecked(statusFetched:boolean){
        apiCallDone.value = statusFetched;
    }

    const getFeePaidVal = computed(
        () => {
            return feeIsPaid.value;
        }
    );

    function setFeePaidVal(feePaid:boolean){
        feeIsPaid.value = feePaid;
    }

    const getPOPUploadedVal = computed(
        () => {
            return POPUploaded.value;
        }
    );

    function setPOPUploadedVal(POPDocUploaded:boolean){
        POPUploaded.value = POPDocUploaded;
    }

    const getDocFetchedVal = computed(
        () => {
            return paymentDocsFetched.value;
        }
    );

    function setDocFetchedVal(docFetched:boolean){
        paymentDocsFetched.value = docFetched;
    }
    const getSelectedFormSection = computed(
        () => {
            return currentSelectedSection.value;
        }
    );

    // function setIsQualificationsVal(isQualifications:boolean){
    //     isQualificationsExists.value = isQualifications;
    // }

    const getIsQualificationsVal = computed(() => {
        const modalCollection = ModalCollection.value;
        // Just check the length of ther modal collection
        return modalCollection[SectionDescription.Qualification] ? modalCollection[SectionDescription.Qualification].length > 0 : false;
    });

    // function setIsBackgroundVal(isBackground:boolean){
    //     isBackgroundExists.value = isBackground;
    // }

    const getIsBackgroundVal = computed(() => {
        const modalCollection = ModalCollection.value;
        // Just check the length of ther modal collection
        return modalCollection[SectionDescription.ProfessionalBackgroundWorkExperience] ? modalCollection[SectionDescription.ProfessionalBackgroundWorkExperience].length > 0 : false;
    });

    function setSelectedFormSection(selectedForm:IFormSection){
        currentSelectedSection.value = selectedForm;
    }

    function resetSelectedFormSection() {
        let generalSection: IFormSection | null = null;
        if (subProgrammeConfiguration.value != null){
            // Assuming that store.sections is an array of IFormSection
            subProgrammeConfiguration.value?.getSectionList().forEach((section: IFormSection) => {
                if (section.Description === 'General') {
                    generalSection = section;
                }
            });

            if (generalSection !== null) {
                setSelectedFormSectionState(generalSection, FormSectionStates.Incomplete);
            } else {
                console.log('General section not found');
                let firstSection = subProgrammeConfiguration.value?.getSectionList()[0];
                setSelectedFormSectionState(firstSection, firstSection.State);
            }
        }
    }

    function setSelectedFormSectionState(section: IFormSection, state: any){
        if (section != null){
            if (state == FormSectionStates.Optional){
                section.IsOptional = true;
            }
            section.PreviousState = section.State;
            section.State = state;
        }
    }

     //Actions
    async function fetchSubProgrammeConfiguration(subProgrammeName: string) {
        let subProgrammeData: z.infer<typeof SubProgrammeConfigQuery> = null;
        let subProgrammeUri = decodeURI(subProgrammeName);
        // Remove trailing '-' from subProgrammeUri if it is present
        if (subProgrammeUri.endsWith('-')) {
            subProgrammeUri = subProgrammeUri.slice(0, -1);
        }

        await fetchJsonData(`forms/${subProgrammeUri}`)
            .then(subProgrammeConfigQueryJson => {
                subProgrammeData = parseJSONObject(SubProgrammeConfigQuery, subProgrammeConfigQueryJson);
            })
            .catch(error => {
                // Log error to Azure Log Analytics or Alert GIBS
                console.error(JSON.stringify(error, null, 2))
                subProgrammeData = null;
            })
            .finally(() => {
                subProgrammeConfig.value = subProgrammeData;
                subProgrammeConfiguration.value = new SubProgrammeConfiguration(subProgrammeData);
            });
    }

    async function fetchLookupDataById(key: string, lookupId: string): Promise<IFormTypeAheadItem[]> {
        try { 
            const response = await fetchJsonData(`reference/${key}`);
            // Transform the response into JSON
            const jsonData = typeof response === 'string' ? JSON.parse(response) : response;
            
            // find the matching IFormTypeAheadItem by value for the lookupId
            const result = jsonData.find((item: IFormTypeAheadItem) => {
                return item.Value === lookupId;
            });

            return result as IFormTypeAheadItem[];
        }
        catch (error: any) {  
            throw createError({
                statusCode: 400,
                statusMessage:  'Unable to fetch lookup data by id.'
            }); 
        }
        
    }

    async function fetchLookupData(crmEntity: CRMEntity, key: string, filter: string): Promise<IFormTypeAheadItem[]> {
        const formData: IFormLookupDataCmd = {
            Entity: crmEntity,
            Atttribute: key,
            FilterString: filter
        }

        try { 
            const response = await fetchJsonData(`reference/${key}`);
            // Transform the response into JSON
            const jsonData = typeof response === 'string' ? JSON.parse(response) : response;
            
            // Transform the data into an array of IFormTypeAheadItem
            const result = jsonData.map((item: IFormTypeAheadItem) => {
                return {
                    Text: item.Text,
                    Value: item.Value,
                    ShortText : item.ShortText,
                };
            });

            return result as IFormTypeAheadItem[];
        }
        catch (error: any) {  
            throw createError({
                statusCode: 400,
                statusMessage:  'Unable to fetch lookup data by key.'
            }); 
        }
    }

    // function addFormTypeAheadItemsStore(key: string, item:IFormTypeAheadItem){
    //     try{
    //         if (formTypeAheadItems.value.find(_ => _.Key === key) === undefined || null){
    //             formTypeAheadItems.value.push({Key: key, Value: new Array<IFormTypeAheadItem>()});
    //         }

    //         if (formTypeAheadItems.value.find(_ => _.Key === key)?.Value.find(_ => _.Text === item.Text) === undefined || null){ 
    //             formTypeAheadItems.value.find(_ => _.Key === key)?.Value.push(item);
    //         }
    //     }
    //     catch(error){
    //         debugger;
    //     }
    // }

    // function getFormTypeAheadItemsStore(key: string) : Array<IFormTypeAheadItem> {
    //     if (formTypeAheadItems.value.find(_ => _.Key === key) === undefined || null){
    //         formTypeAheadItems.value.push({Key: key, Value: new Array<IFormTypeAheadItem>()});
    //     }
    //     return formTypeAheadItems.value.find(_ => _.Key === key)?.Value as Array<IFormTypeAheadItem>;
    // }

    function setLoader(_loader : typeof GibsControlLoading){
        loader.value = _loader;
    }

    function getLoader() : typeof GibsControlLoading {
        return loader.value;
    }

    function setBusySaving(busy:Boolean){
        busySaving.value = busy;
    }

    function getBusySaving() : typeof Boolean {
        return busySaving.value;
    }

    function setGlobalEnrolmentId(enrolmentId: string){
        globalEnrolmentId.value = enrolmentId;
    }

    function getGlobalEnrolmentId() : string{
        return globalEnrolmentId.value;
    }

    // function setFetchRequest(request: string){
    //     requests.value.push({request: request, date: new Date(), 'status': 'Busy'});
    // }

    // function removeFetchRequest(request: string){
    //     let currentRequest = requests.value.find(_ => _.request === request);
    //     if (currentRequest != null || undefined){
    //         requests.value.splice(requests.value.indexOf(currentRequest), 1);
    //     }
    // }

    // function getFetchRequest(request: string){
    //     return requests.value.find(_ => _.request === request);
    // }

    // function getFetchRequests(){
    //     return requests.value;
    // }

    return {
        ProgrammeName,
        SelectedFormSection,
        SubProgrammeConfig,
       // QualificationList,
       // QualificationFieldInfo,
        // AdditionalDelegateList,
        // AdditionalDelegateFieldInfo,
        getSelectedFormSection,
        getSubProgrammeConfig,
        ModalCollection,
        CurrentDocuments,
        PaymentDocuments,
        fetchSubProgrammeConfiguration,
        fetchLookupData,
        fetchLookupDataById,
        setSelectedFormSection,
        resetSelectedFormSection,
        setSelectedFormSectionState,
        // addFormTypeAheadItemsStore,
        // getFormTypeAheadItemsStore,
        setLoader,
        getLoader,
        setBusySaving,
        getBusySaving,
        setGlobalEnrolmentId,
        getGlobalEnrolmentId,
        getPaymentStatusChecked,
        setPaymentStatusChecked,
        getFeePaidVal,
        setFeePaidVal,
        //setIsQualificationsVal,
        getIsQualificationsVal,
        //setIsBackgroundVal,
        getIsBackgroundVal,
        getPOPUploadedVal,
        setPOPUploadedVal,
        getDocFetchedVal,
        setDocFetchedVal
        // setFetchRequest,
        // removeFetchRequest,
        // getFetchRequest,
        // getFetchRequests,
    }
});