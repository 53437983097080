import { z } from "zod";
import { FormValidator, FormSectionStates, SectionDescription, ExternalValidationStates } from "~/utils/enums";
import { SubProgrammeConfigurationError } from "~/classes/validation-errors";
import { showInteractionFunction } from "~/utils/interactions";

export class SubProgrammeConfiguration {
    private programmeID: string = '';
    private programmeCode: string = '';
    private programmeName: string = '';
    private freeProgramme: boolean = false;
    private isEasyForm: boolean = false;
    private customProgramme: boolean = false;
    private formLevel: string = '';
    private contactName: string = '';
    private contactEmail: string = '';
    private fieldList: Array<IFormSubProgrammeField> = new Array<IFormSubProgrammeField>();
    private sectionList: Array<IFormSection> = new Array<IFormSection>();
    private interactionFunctionTemplateList: Array<IInteractionFunctionTemplate> = new Array<IInteractionFunctionTemplate>();
    private conditionalComparatorFunctionTemplateList: Array<IConditionalComparatorFunctionTemplate> = new Array<IConditionalComparatorFunctionTemplate>();
    private validationFunctionTemplateList: Array<IValidationFunctionTemplate> = new Array<IValidationFunctionTemplate>();
    private errorList: Array<Error> = [];
    private orderIndex : number = 0;

    constructor(configuration: (z.infer<typeof SubProgrammeConfigQuery>)) {

        if (typeof(configuration) !== "undefined" && configuration !=  null) {
            this.programmeName = (typeof(configuration.ProgrammeName) !== 'undefined') ? configuration.ProgrammeName: '';
            this.programmeID = (typeof(configuration.ProgrammeID) !== 'undefined') ? configuration.ProgrammeID: '';
            this.programmeCode = (typeof(configuration.ProgrammeCode) !== 'undefined') ? configuration.ProgrammeCode: '';
            this.formLevel = configuration.FormLevel || '';
            this.freeProgramme = configuration.FreeProgramme || false;
            this.isEasyForm = configuration.isEasyForm || false;
            this.customProgramme = configuration.isCustomProgramme || false;
            this.contactName = (typeof(configuration.ContactName) !== 'undefined') ? configuration.ContactName: '';
            this.contactEmail = (typeof(configuration.ContactEmail) !== 'undefined') ? configuration.ContactEmail: '';
            //Check if the formLevel is not empty
            
            // if ( this.formLevel.length == 0){
            //     const error = new SubProgrammeConfigurationError('Form Level for this programme is not valid or not set.');
            //     this.errorList.push(error);
            // }


            if (typeof(configuration.fields) !== 'undefined' && configuration.fields != null) {
                let fields: Array<IFormSubProgrammeField> = configuration.fields.map((formField, formFieldIndex) => { 
                    /**
                     * BF: Rule Assumptions for the Validator and ValidatorRules property of the IFormSubProgrammeField
                     *  Rule: if Validator="Display" = no Validation unless a ValidatorRules is supplied e.g. ValidatorRules="emailValidator" then it is applied if a value is entered.
                     *  Rule: if Validator="Display as Manditory" ;  
                     *                          ValidatorRules="isRequired" for Type:"Text" or 
                     *                          ValidatorRules="OptionSelected" for Type:"OptionSet" or Type:"Lookup" or 
                     *                          ValidatorRules="isChecked" for Type:"Boolean" or
                     *                          ValidatorRules="documentUploaded" for Type:"FileUpload" by default, then append additional Validator Functions from ValidatorRules property.
                     */

                    // Rules from old code
                    let isMandatory = (formField.Validator == FormValidator.DisplayAsMandatory) ? true: false;

                    //Additional Validation 
                    //BF: Leave this is how we will parse ot the Attribute JSON
                    let otherValueAttribute: string | null = null;

                    let fieldId = `${formField.Atttribute}_${formField.Field}`;
                    
                    if (formField.Attributes != null) {
                        // configField.Attributes must be a json array string
                        if (/^\[\{.*\}\]$/.test(formField.Attributes)) {
                            let fieldAttributes: Array<IFormSubProgrammeFieldAttribute>;
                            try {
                                fieldAttributes = JSON.parse(formField.Attributes);
                            }
                            catch (err: any) {
                                const message = `Attribute Json parse error \n ${formField.Atttribute} \n (field: ${formField.Field}) \n (value: '${formField.Attributes}')`;
                                const error = new SubProgrammeConfigurationError(message);
    
                                this.errorList.push(error);
                                fieldAttributes = [];
                            }
                        
                            // Get  data-other Attribute
                            let parsedAttribute = fieldAttributes.find((attributeObject: IFormSubProgrammeFieldAttribute) => {
                                return attributeObject.name === 'data-other';
                            });
                            if (typeof (parsedAttribute) !== 'undefined') {
                                otherValueAttribute = parsedAttribute.value;
                            }

                            // let requiredAttribute = fieldAttributes.find((attributeObject) => {
                            //     return (attributeObject.name === 'required'  && attributeObject.value =='required');
                            // });
                            // if (typeof(requiredAttribute) !== 'undefined') {
                            //     isMandatory = true;
                            // }
                        }
                    }

                    let interactionTrueFunction: Function | null = null;
                    let interactionFalseFunction: Function | null = null;
                    let conditonalComparatorFunction: Function | null = null;
                    if((typeof(formField.Receiver) !== 'undefined' && formField.Receiver != null)){
                        
                        switch(formField.Receiver) {
                            case FormReceiverType.ShowOtherField: 
                                interactionTrueFunction = showInteractionFunction;
                                interactionFalseFunction = hideInteractionFunction;
                            break;
                            case FormReceiverType.ShowAltOtherField:
                                interactionTrueFunction = hideInteractionFunction;
                                interactionFalseFunction = showInteractionFunction;
                            break;
                        }
                    }

                    // TODO: This logic exists in many places, extract as export from utils
                    // TODO: @Neil - This is where we need to add the "other" other field overrides from blob storage
                    // Create list of Conditional Comparator Validation Templates
                    // if((typeof(formField.Sender) !== 'undefined' && formField.Sender != null)) {
                    //     if(typeof(formField.Receiver) !== 'undefined' &&  formField.Receiver != null) {
                    //         let conditionalComparator:IConditionalComparatorFunctionTemplate = {
                    //             validationRuleId: formField.Atttribute,
                    //             validationRuleType: FormValidatorRuleType.ConditionalComparator,
                    //             sourceValue: '',
                    //             comparatorRuleId: getGibsAttributeKey(formField.Sender),
                    //             comparatorValue: '',
                    //             conditionalValue: otherValueAttribute,
                    //             interactionRuleId: formField.Atttribute
                    //         }
                    //         this.conditionalComparatorFunctionTemplateList.push(conditionalComparator);

                    //         let interaction:IInteractionFunctionTemplate = {
                    //             validationRuleId: formField.Atttribute,
                    //             validationRuleType: FormValidatorRuleType.Interaction,
                    //             sourceValue: '',
                    //             comparatorRuleId: getGibsAttributeKey(formField.Sender),
                    //             comparatorValue:'',
                    //             interactionTrue: interactionTrueFunction,
                    //             interactionFalse: interactionFalseFunction
                    //         }
                    //         this.interactionFunctionTemplateList.push(interaction);
                    //     }
                    // }

                    // TODO: This logic exists in many places, extract as export from utils
                    // FormValidator.DisplayAsMandatory means formField.ValidatorRules = 'isRequired'
                    // even when formField.ValidatorRules == null
                    if (formField.Validator === FormValidator.DisplayAsMandatory && formField.ValidatorRules == null) {
                        // ValidatorRules="isRequired" for Type:"Text" or 
                        // ValidatorRules="OptionSelected" for Type:"OptionSet" or Type:"Lookup" or 
                        // ValidatorRules="isChecked" for Type:"Boolean" or
                        // ValidatorRules="documentUploaded" for Type:"FileUpload" by default, then append additional Validator Functions from ValidatorRules property.
                        // add sane validators for missing mandatory field rule based on the formField.Type
                        switch (formField.Type) {
                            case CRMControlType.Text:
                                formField.ValidatorRules = 'isRequired';
                                break;
                            case CRMControlType.OptionSet:
                            case CRMControlType.Lookup:
                                formField.ValidatorRules = 'optionSelected';
                                break;
                            case CRMControlType.Boolean:
                                formField.ValidatorRules = 'isChecked';
                                break;
                            case CRMControlType.FileUpload:
                                formField.ValidatorRules = 'documentUploaded';
                                break;
                            case CRMControlType.DateTime:
                                formField.ValidatorRules = 'isRequired|isValidDate';
                                break;
                            default:
                                formField.ValidatorRules = 'isRequired';
                                break;
                        }
                    }

                    // add sane validator for missing non-mandatory field rule based on the formField.Type
                    if (formField.Validator === FormValidator.Display && formField.ValidatorRules == null) {
                        formField.ValidatorRules = 'optionalValidator';
                    }

                    // Now, sanity check validators and validator rules before applying them
                    // TODO : Also cater for the case where the ValidatorRules are still inconsistent with the Validator
                    //        FormValidator.DisplayAsMandatory has optionalValidator
                    //        FormValidator.Display has isRequired, optionSelected, isChecked, documentUploaded, notEmptyValidator
                    //        isLegalTermsAccepted is only for the Legal Section and is always present
                    //
                    //        Also, loop formField.ValidatorRules.split('|') and check for inconsistent/incongruent rules
                    //        e.g. isRequired and notEmptyValidator
                    //             isRequired and optionSelected
                    //             isRequired and isChecked
                    //             isRequired and documentUploaded
                    //             isRequired and isLegalTermsAccepted
                    //             optionSelected and isChecked
                    //             optionSelected and documentUploaded
                    //             optionSelected and isLegalTermsAccepted
                    //             isChecked and documentUploaded
                    //             isChecked and isLegalTermsAccepted
                    //             documentUploaded and isLegalTermsAccepted
                    //             notEmptyValidator and isLegalTermsAccepted
                    //             notEmptyValidator and optionSelected
                    //             notEmptyValidator and isChecked
                    //             notEmptyValidator and documentUploaded
                    //             notEmptyValidator and isRequired
                    //             optionalValidator and isRequired
                    //             optionalValidator and isLegalTermsAccepted
                    //             optionalValidator and optionSelected
                    //             optionalValidator and isChecked
                    //             the list goes on...
                    //
                    //        To be tested: If FormValidator.Display and ValidatorRules is alphaNumericValidator, emailValidator,
                    //                      numericValidator, zaPostalCodeValidator, zaPhoneNumberValidator or inputMaxLength160CharactersValidator
                    //                      Then these only fire if the user inputs anything into the field

                    // Create Validation Template
                    if ((typeof (formField.ValidatorRules) !== 'undefined' && formField.ValidatorRules != null)) {
                        let validationRules = formField.ValidatorRules.split('|');

                        let validatorList: Array<Function> =  [];
                        validationRules.forEach((validationFunction) => {
                            // Default Validation
                            let validatorFunction: Function = optionalValidator;
                            switch (validationFunction?.trim()) {
                                case 'alphaNumericValidator':
                                    validatorFunction = alphaNumericValidator
                                    break;
                                case 'emailValidator': 
                                    validatorFunction =  emailValidator;
                                    break;
                                case 'isChecked':
                                    validatorFunction =  isChecked;
                                    break;
                                case 'isLegalTermsAccepted':
                                    validatorFunction = isLegalTermsAccepted
                                    break;
                                case 'isRequired':
                                    // FormValidator.Display makes no sense as isRequired because optional fields cannot be mandatory
                                    if (formField.Validator === FormValidator.DisplayAsMandatory) {
                                        validatorFunction =  isRequired;
                                    }
                                    break;
                                case 'isValidDate':
                                    validatorFunction =  isValidDate;
                                    break;
                                case 'isValidPhoneNumber':
                                    validatorFunction = isValidPhoneNumber;
                                    break;
                                case 'notEmptyValidator':
                                    // FormValidator.Display makes no sense as notEmptyValidator because optional fields cannot be mandatory
                                    if (formField.Validator === FormValidator.DisplayAsMandatory) {
                                        validatorFunction =  notEmptyValidator;
                                    }
                                    break; 
                                case 'numericValidator':
                                    validatorFunction =  numericValidator;
                                    break;
                                case 'optionSelected':
                                case 'OptionSelected':
                                    // FormValidator.Display makes no sense as optionSelected because optional fields cannot be mandatory
                                    if (formField.Validator === FormValidator.DisplayAsMandatory) {
                                        validatorFunction = optionSelected;
                                    }
                                    break;
                                case 'optionalValidator':
                                    // FormValidator.DisplayAsMandatory makes no sense with optionalValidator because mandatory fields cannot be optional
                                    if (formField.Validator === FormValidator.Display) {
                                        validatorFunction = optionalValidator;
                                    }
                                    break;
                                case 'inputMaxLength160CharactersValidator':
                                    validatorFunction = inputMaxLength160CharactersValidator;
                                    break;
                                case 'zaPostalCodeValidator':
                                    validatorFunction = zaPostalCodeValidator;
                                    break;
                                case 'isSouthAfricanId' :
                                    validatorFunction = isSouthAfricanId;
                                    break;
                                case 'zaPhoneNumberValidator':
                                    validatorFunction = zaPhoneNumberValidator;
                                    break;
                                default:
                                    validatorFunction = optionalValidator;
                                    break;
                            }

                            if (typeof(validatorFunction) !== 'undefined') {                            
                                validatorList.push(validatorFunction);
                            } 
                        });

                        if (validatorList.length > 0) {
                            let validator: IValidationFunctionTemplate = {
                                validationRuleType: FormValidatorRuleType.Validator,
                                validationRuleId: formField.Atttribute,
                                sourceValue: '',
                                validator: validatorList
                            }
                            this.validationFunctionTemplateList.push(validator);
                        }
                    }

                    let field: IFormSubProgrammeField = {
                        FieldId: fieldId,
                        Attributes: formField.Attributes,
                        Atttribute: formField.Atttribute,
                        EnteredValue: formField.EnteredValue,
                        Entity: formField.Entity,
                        Field: formField.Field,
                        Hint: formField.Hint,
                        HtmlType: formField.HtmlType,
                        Label: fieldId === 'emailaddress1_brite_04_05_emailaddress' ?
                            formField.Label + ' (please contact support@gibs.co.za to change this email address)' : // Task 5440: ‘Duplicate’ email address on the front-end for forms
                            formField.Label,
                        Level: formField.Level,
                        Limit: formField.Limit,
                        Order: formField.Order,
                        Receiver: formField.Receiver,
                        Section: formField.Section,
                        Sender: formField.Sender,
                        Type: formField.Type,
                        ReadOnly: formField.ReadOnly ,
                        Validator: formField.Validator,
                        ValidatorRules: formField.ValidatorRules,
                        Mandatory: isMandatory,
                        DropDownOptions: new Array<IDropdownItem>(),
                        ValidationRuleTemplateList: new Array(),
                        FieldElement: '' 
                    }

                     // Map the options field to the dropdown vm
                    if (typeof(formField.Options)  !== 'undefined' && formField.Options != null) {
                        if(formField.Options.length > 0) {
                            let dropdownOptions = formField.Options.map((option) => {
                                let dropdownOption: IDropdownItem = {
                                    id: option.Value,
                                    itemText: option.Text,
                                    selected: false
                                }
                                return dropdownOption;
                            });
                            field.DropDownOptions = dropdownOptions;
                        }
                    }
                    return field;
                });
                this.fieldList = fields;
            }
        }
        else {
            this.fieldList = new Array<IFormSubProgrammeField>(); 
        }
        this.extractForm();
    }

    get ProgrammeName() {
        let output = '';
        if (typeof(this.programmeName) !== 'undefined' || this.ProgrammeName != null) 
        {
            output = this.programmeName;
        }
        return output;
    }
    get ProgrammeId() {
        let output = '';
        if (typeof(this.programmeID) !== 'undefined' || this.programmeID != null) 
        {
            output = this.programmeID;
        }
        return output;
    }
    get ProgrammeCode() {
        let output = '';
        if (typeof(this.programmeCode) !== 'undefined' || this.programmeCode != null) 
        {
            output = this.programmeCode;
        }
        return output;
    }
    get ContactName(){
        let output = '';
        if (typeof(this.contactName) !== 'undefined' || this.contactName != null) 
        {
            output = this.contactName;
        }
        return output;
    }
    get ContactEmail(){
        let output = '';
        if (typeof(this.contactEmail) !== 'undefined' || this.contactEmail != null) 
        {
            output = this.contactEmail;
        }
        return output;
    }
    get FormLevel() {
        let output = '';
        if (typeof(this.formLevel) !== 'undefined' || this.formLevel != null) 
        {
            output = this.formLevel;
        }

        return output;
    }
    get IsFreeProgramme() {
        let output = false;
        if (typeof(this.freeProgramme) !== 'undefined' || this.freeProgramme != null) 
        {
            output = this.freeProgramme;
        }
        return output;
    }

    get IsEasyForm() {
        let output = false;

        if (typeof(this.isEasyForm) !== 'undefined' || this.isEasyForm != null) 
        {
            output = this.isEasyForm;
        }

        return output;
    }

    get IsCustomProgramme() {
        let output = false;
        if (typeof(this.customProgramme) !== 'undefined' || this.customProgramme != null) 
        {
            output = this.customProgramme;
        }
        return output;
    }

    get Errors():Array<Error> {
        return this.errorList;
    }

    addSection(sectionName: string) {
        let newSection: IFormSection = {
            Description: sectionName,
            Order: this.sectionOrder(sectionName ?? ""),
            Dynamic: false,
            PreviousState: FormSectionStates.Incomplete,
            State: FormSectionStates.Incomplete,
            Fields: this.fieldList.filter((field) => { return field.Section === sectionName; }),
            ReactiveForm: {},
            Visible: !(sectionName === SectionDescription.OrganizationDetails)
        }
        this.sectionList.push(newSection);
        return this.sectionList;
    }

    removeSection(sectionName: string) {
        this.sectionList = this.sectionList.filter((section) => { return section.Description !== sectionName; });
        return this.sectionList;
    }

    getSectionList (filter: Function  | null = null) {
        const sections = this.sectionList.sort((a,b) => a.Order > b.Order ? 1 : -1);

        /// TODO : if no legalSection Send Mail to support@gibs.co.za to add Section.
        let legalSection = sections.find(_ => _.Description == SectionDescription.Legal);
        if (legalSection != null){
            legalSection.Fields.forEach(field => {
                field.Validator = "Display as Mandatory";
                field.ValidatorRules = 'isLegalTermsAccepted';
            });
        }

        if (filter) {
            return filter(sections);
        }
        return sections;
    }
    getFieldsList () {
        this.changeFieldToLookup("address1_country","brite_06_09_country");
        this.changeFieldToLookup("gibs_nextofkinaddresscountryid","brite_08_07_country");
        this.changeFieldToLookup("gibs_billingaddresscountry","brite_05_17_country");
        this.changeFieldToLookup("address1_country","brite_11_03_countrymaildelivery");
        return this.fieldList;
    }

    changeFieldToLookup(atttribute: string, field: string) {
        const fieldToChange = this.fieldList.find((fieldItem: IFormSubProgrammeField) => {
            if (fieldItem.Atttribute == atttribute 
                && fieldItem.Field == field) {
                    return fieldItem;
            };
        });

        if (fieldToChange != null){
            fieldToChange.HtmlType = "Lookup";
            fieldToChange.Type = "Lookup";
            fieldToChange.ValidatorRules = "OptionSelected";
            fieldToChange.AlternativeAtttribute = "gibs_nationality";
            fieldToChange.AlternativeEntity = "contact"
        }
    }

    extractForm() {
        this.sectionList = new Array();

    /// Also exclude easy apply when we doing the form. 
    if (this.formLevel === 'Level1' || this.formLevel === 'Level2') 
    {
        // TODO: Validate this is correct and should be injected
        //Check Dynamic Section for Additional Delegates and inject these fields in
        const titleField =  this.fieldList.find((field: IFormSubProgrammeField) => {
            if (field.Atttribute == 'gibs_title' 
                    && field.Field =='brite_07_01_title'
                    && field.Section == SectionDescription.AdditionalDelegates) {
                return field;
            };
        });
        if(titleField){
            titleField.Validator = "Display as Mandatory";
            titleField.Mandatory = true;
        }


            // if(!titleField){
            //     const field: IFormSubProgrammeField = {
            //         "FieldId": "gibs_title_brite_07_01_title",
            //         "Visible":false,
            //         "Attributes": null,
            //         "Atttribute": "gibs_title",
            //         "EnteredValue": null,
            //         "Entity": "contact",
            //         "Field": "brite_07_01_title",
            //         "Hint": null,
            //         "HtmlType": null,
            //         "Label": "Title",
            //         "Level": "1,2,3,4",
            //         "Limit": null,
            //         "Order": 117,
            //         "Receiver": null,
            //         "Section": "Additional Delegates",
            //         "Sender": null,
            //         "Type": "OptionSet",
            //         "Validator": "Display as Mandatory",
            //         "ValidatorRules": null,
            //         "Mandatory": true,
            //         "DropDownOptions": [
            //             {
            //             "id": "449710000",
            //             "itemText": "A/Prof",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710001",
            //             "itemText": "Adv",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710022",
            //             "itemText": "Amb",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710002",
            //             "itemText": "Brig",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710003",
            //             "itemText": "Capt",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710004",
            //             "itemText": "Col",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710005",
            //             "itemText": "Comd",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710006",
            //             "itemText": "Dr",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710007",
            //             "itemText": "Ds",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710008",
            //             "itemText": "Gen",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710009",
            //             "itemText": "Judge",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710010",
            //             "itemText": "Lt",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710011",
            //             "itemText": "Lt-Gen",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710012",
            //             "itemText": "Maj",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710013",
            //             "itemText": "Miss",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710014",
            //             "itemText": "Mr",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710015",
            //             "itemText": "Mrs",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710016",
            //             "itemText": "Ms",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710023",
            //             "itemText": "Mx",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710017",
            //             "itemText": "Past",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710018",
            //             "itemText": "Prof",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710019",
            //             "itemText": "Rev",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710020",
            //             "itemText": "Sir",
            //             "selected": false
            //             },
            //             {
            //             "id": "449710021",
            //             "itemText": "Sr",
            //             "selected": false
            //             }
            //         ],
            //         "ValidationRuleTemplateList": [
            //             {
            //             "isActive": true,
            //             "mandatory": false,
            //             "sourceValue": "",
            //             "validationRuleId": "gibs_title",
            //             "validationRuleType": "Validator",
            //             "validators": [
            //                 null
            //             ]
            //             }
            //         ],
            //         "FieldElement": ""
            //     };
            //     this.fieldList.push(field);
            // }

            const firstNameField = this.fieldList.find((field: IFormSubProgrammeField) => {
                if (field.Atttribute == 'firstname' 
                    && field.Field == 'brite_07_02_fullnames'
                    && field.Section == SectionDescription.AdditionalDelegates) {
                        return field;
                };
            });

            if(firstNameField){
                firstNameField.Validator = "Display as Mandatory";
                firstNameField.Mandatory = true;
            }

            // if(!firstNameField) {
            //     const field: IFormSubProgrammeField = {
            //         "FieldId": "firstname_brite_07_02_fullnames",
            //         "Visible":false,
            //         "Attributes": null,
            //         "Atttribute": "firstname",
            //         "EnteredValue": null,
            //         "Entity": "contact",
            //         "Field": "brite_07_02_fullnames",
            //         "Hint": null,
            //         "HtmlType": null,
            //         "Label": "Full names",
            //         "Level": "1,2,3,4",
            //         "Limit": null,
            //         "Order": 118,
            //         "Receiver": null,
            //         "Section": "Additional Delegates",
            //         "Sender": null,
            //         "Type": "Text",
            //         "Validator": "Display as Mandatory",
            //         "ValidatorRules": null,
            //         "Mandatory": true,
            //         "DropDownOptions": [],
            //         "ValidationRuleTemplateList": [
            //             {
            //             "isActive": true,
            //             "mandatory": false,
            //             "sourceValue": "",
            //             "validationRuleId": "firstname",
            //             "validationRuleType": "Validator",
            //             "validators": [
            //                 null
            //             ]
            //             }
            //         ],
            //         "FieldElement": ""
            //         };
            //         this.fieldList.push(field);
            // }

            const lastNameField = this.fieldList.find((field: IFormSubProgrammeField) => {
                if (field.Atttribute == 'lastname'
                        && field.Field == 'brite_07_03_surname' 
                        && field.Section == SectionDescription.AdditionalDelegates) {
                    return field;
                };
            });

            if(lastNameField){
                lastNameField.Validator = "Display as Mandatory";
                lastNameField.Mandatory = true;
            }
            // if(!lastNameField) {
            //     const field: IFormSubProgrammeField = {
            //         "FieldId": "lastname_brite_07_03_surname",
            //         "Visible":false,
            //         "Attributes": null,
            //         "Atttribute": "lastname",
            //         "EnteredValue": null,
            //         "Entity": "contact",
            //         "Field": "brite_07_03_surname",
            //         "Hint": null,
            //         "HtmlType": null,
            //         "Label": "Surname",
            //         "Level": "1,2,3,4",
            //         "Limit": null,
            //         "Order": 119,
            //         "Receiver": null,
            //         "Section": "Additional Delegates",
            //         "Sender": null,
            //         "Type": "Text",
            //         "Validator": "Display as Mandatory",
            //         "ValidatorRules": null,
            //         "Mandatory": true,
            //         "DropDownOptions": [],
            //         "ValidationRuleTemplateList": [
            //             {
            //             "isActive": true,
            //             "mandatory": false,
            //             "sourceValue": "",
            //             "validationRuleId": "lastname",
            //             "validationRuleType": "Validator",
            //             "validators": [
            //                 null
            //             ]
            //             }
            //         ],
            //         "FieldElement": ""
            //         };
            //         this.fieldList.push(field);
            // }

            const emailAddressField = this.fieldList.find((field: IFormSubProgrammeField) => {
                if (field.Atttribute == 'emailaddress'
                    && field.Field == 'brite_07_04_emailaddress'
                    && field.Section == SectionDescription.AdditionalDelegates) {
                    return field;
                };
            });

            if(emailAddressField){
                emailAddressField.Validator = "Display as Mandatory";
                emailAddressField.Mandatory = true;
                emailAddressField.IsExternalValidation = true,
                emailAddressField.ExternalValidationState  = ExternalValidationStates.None,
                emailAddressField.ExternalValidation =  () => {return true;}
            }

            // if(!emailAddressField){
            //     const field: IFormSubProgrammeField = {
            //         "FieldId": "emailaddress_brite_07_04_emailaddress",
            //         "Visible":false,
            //         "Attributes": null,
            //         "Atttribute": "emailaddress",
            //         "EnteredValue": null,
            //         "Entity": "new_enrolment",
            //         "Field": "brite_07_04_emailaddress",
            //         "Hint": null,
            //         "HtmlType": null,
            //         "Label": "Email address",
            //         "Level": "1,2,3,4",
            //         "Limit": null,
            //         "Order": 121,
            //         "Receiver": "copyValue",
            //         "Section": "Additional Delegates",
            //         "Sender": "emailaddress1_brite_07_04_emailaddress",
            //         "Type": "Text",
            //         "Validator": "Display as Mandatory",
            //         "ValidatorRules": "emailValidator",
            //         "Mandatory": true,
            //         "DropDownOptions": [],
            //         "ValidationRuleTemplateList": [],
            //         "FieldElement": "",
            //         "IsExternalValidation" : true,
            //         "ExternalValidationState" : ExternalValidationStates.None,
            //         "ExternalValidation" : () => {return true;}
            //     };
            //     this.fieldList.push(field);
            // }
            //else{
            if(emailAddressField){
                emailAddressField.Validator = "Display as Mandatory";
                emailAddressField.Mandatory = true;
                emailAddressField.IsExternalValidation = true,
                emailAddressField.ExternalValidationState  = ExternalValidationStates.None,
                emailAddressField.ExternalValidation =  () => {return true;}
            }
           // }

            const emailAddress1Field = this.fieldList.find((field: IFormSubProgrammeField) => {
                if(field.Atttribute == 'emailaddress1'
                    && field.Field == 'brite_07_04_emailaddress'
                    && field.Section == SectionDescription.AdditionalDelegates) {
                        return field;
                };
            });
            if(emailAddress1Field){
                emailAddress1Field.Validator = "Display as Mandatory";
                emailAddress1Field.Mandatory = true;
                emailAddress1Field.IsExternalValidation = true,
                emailAddress1Field.ExternalValidationState  = ExternalValidationStates.None,
                emailAddress1Field.ExternalValidation =  () => {return true;}
            }

            // if(!emailAddress1Field){
            //     const field: IFormSubProgrammeField ={
            //         "FieldId": "emailaddress1_brite_07_04_emailaddress",
            //         "Visible":false,
            //         "Attributes": null,
            //         "Atttribute": "emailaddress1",
            //         "EnteredValue": null,
            //         "Entity": "contact",
            //         "Field": "brite_07_04_emailaddress",
            //         "Hint": null,
            //         "HtmlType": null,
            //         "Label": "Email address",
            //         "Level": "1,2,3,4",
            //         "Limit": null,
            //         "Order": 120,
            //         "Receiver": null,
            //         "Section": "Additional Delegates",
            //         "Sender": null,
            //         "Type": "Text",
            //         "Validator": "Display as Mandatory",
            //         "ValidatorRules": "emailValidator",
            //         "Mandatory": true,
            //         "DropDownOptions": [],
            //         "ValidationRuleTemplateList": [
            //             {
            //             "isActive": true,
            //             "mandatory": false,
            //             "sourceValue": "",
            //             "validationRuleId": "emailaddress1",
            //             "validationRuleType": "Validator",
            //             "validators": [
            //                 null
            //             ]
            //             }
            //         ],
            //         "FieldElement": "",
            //         "IsExternalValidation" : true,
            //         "ExternalValidationState" : ExternalValidationStates.None,
            //         "ExternalValidation" : () => {return true;}
            //         };
            //         this.fieldList.push(field);
            // } 
        }

        const profileFormEmailField = this.fieldList.find((field: IFormSubProgrammeField) => {
            if (field.Atttribute == 'username'
                    && field.Field == 'brite_04_05_emailaddress' 
                    && field.Section == SectionDescription.PersonalInfo) {
                return field;
            };
        });

        if(profileFormEmailField){
            profileFormEmailField.ReadOnly = true; 
        }

        // Iterate on all fields in the config and separate by section
        this.fieldList.forEach((field) => {

            //Add ValidationFunctions to the Field
            let validationFunctions: Array<IValidationRule>;
            let validationFunctionList: Array<IValidationFunctionTemplate>;
            validationFunctionList = this.validationFunctionTemplateList.filter((validationTemplate) => {
                return validationTemplate.validationRuleId === field.Atttribute;
            });
            
            if(typeof(validationFunctionList) !== 'undefined') {
                validationFunctions =validationFunctionList.map((validationTemplate) => {
                    let validatorFunction: IValidationRule = {
                        isActive: true,
                        mandatory: field.Mandatory,
                        sourceValue: '',
                        validationRuleId: field.Atttribute,
                        validationRuleType: validationTemplate.validationRuleType,
                        validators: validationTemplate.validator     
                    }
                    return validatorFunction;
                });
            } else {
                validationFunctions = [];
            }
            field.ValidationRuleTemplateList = field.ValidationRuleTemplateList.concat(validationFunctions);

              //Add conditionalComparatorFunctions  to the Field
              validationFunctions: Array<IValidationRule>;
              let conditionalComparatorFunctionList: Array<IConditionalComparatorFunctionTemplate>;
              conditionalComparatorFunctionList = this.conditionalComparatorFunctionTemplateList.filter((condtionalComparatorTemplate) => {
                  return condtionalComparatorTemplate.comparatorRuleId === field.Atttribute;
              });

              if(typeof(conditionalComparatorFunctionList) !== 'undefined') {
                    validationFunctions =conditionalComparatorFunctionList.map((condtionalComparatorTemplate) => {
                    let validatorFunction: IValidationRule = {
                        validationRuleType: condtionalComparatorTemplate.validationRuleType,
                        isActive: true,
                        mandatory: field.Mandatory,
                        validationRuleId: condtionalComparatorTemplate.validationRuleId,
                        sourceValue: '',
                        comparatorRuleId: condtionalComparatorTemplate.comparatorRuleId,
                        comparatorValue: '',
                        conditionalValue: condtionalComparatorTemplate.conditionalValue,
                        interactionRuleId: condtionalComparatorTemplate.interactionRuleId
                    }
                    return validatorFunction;
                });
            } else {
                validationFunctions = [];
            }
              
              if(typeof(conditionalComparatorFunctionList) !== 'undefined') {
                  field.ValidationRuleTemplateList = field.ValidationRuleTemplateList.concat(validationFunctions);
              }

            

            // Create FormSections
            let existingSection: IFormSection | null | undefined;
            // Only include fields that validator="Display|Display as Mandatory"
            if (field.Validator == FormValidator.Display || field.Validator == FormValidator.DisplayAsMandatory)  {
                if (!this.hasSection(field.Section)
                    && field.Section !== SectionDescription.ProgrammeRegistrationOptions) {
                    let isDynamic = (field.Section == SectionDescription.Qualification 
                                                        || field.Section == SectionDescription.ProfessionalMemberships 
                                                        || field.Section == SectionDescription.ProfessionalBackgroundWorkExperience
                                                        || field.Section == SectionDescription.AdditionalDelegates) ? true: false;

                    let newSection: IFormSection = {
                        Description: field.Section,
                        Order: this.sectionOrder(field.Section ?? ""),
                        Dynamic: isDynamic,
                        State: FormSectionStates.Incomplete,
                        Fields: [],
                        ReactiveForm: {},
                        Visible: !(field.Section === SectionDescription.OrganizationDetails)
                    }
                    this.sectionList.push(newSection);
                    existingSection = newSection;
                }
                else {
                    existingSection = this.getSection(field.Section);
                }
    
                if (existingSection != null) {
                   
                     // Add fields to the sections
                    existingSection.Fields.push(field);
    
                    // Add field to form object that will be reactive
                    let formValue ;
                    if (field.Type === CRMControlType.Boolean) {
                        formValue = null; 
                    }
                    else if (field.Type === CRMControlType.DateTime) {
                        formValue = "";
                    }
                    else if (field.Type === CRMControlType.Lookup) {
                        formValue = "";
                    }
                    else if (field.Type === CRMControlType.OptionSet) {
                        formValue = null;
                    }
                    else if (field.Type === CRMControlType.Text) {
                        formValue = '';
                    }
                 
                    if (typeof(formValue) !== 'undefined' && formValue != null) {
                        existingSection.ReactiveForm[field.Atttribute] = formValue;
                    }
                    
                }
                // dynamic array
                if (existingSection && existingSection.Dynamic) {
                    if (existingSection.Description === SectionDescription.Qualification) {
                        existingSection.ReactiveForm['QualificationList'] = [];
                    } else if (existingSection.Description === SectionDescription.ProfessionalMemberships) {
                        existingSection.ReactiveForm['ProfessionalMembershipsList'] = [];
                    } else if (existingSection.Description === SectionDescription.ProfessionalBackgroundWorkExperience) {
                        existingSection.ReactiveForm['ProfessionalBackgroundWorkExperienceList'] = [];
                        existingSection.ReactiveForm['gibs_totalnumberofyearsinmanagement'] = '';
                        existingSection.ReactiveForm['gibs_totalnumberofyears'] = '';
                    }
                }
            }
        });


        // BF: This is so that i can get the dynamic field element to do a hideshow bad techdebt
        this.sectionList.forEach((section) => {
            section.Fields.forEach((field) => {

                //Add InteractionFunctions  to the Field
                let validationFunctions: Array<IValidationRule>;
                let interactionFunctionList: Array<IInteractionFunctionTemplate>;
                interactionFunctionList = this.interactionFunctionTemplateList.filter((interactionTemplate) => {
                    return interactionTemplate.comparatorRuleId === field.Atttribute;
                });
    
                if(typeof(interactionFunctionList) !== 'undefined') {
                    validationFunctions =interactionFunctionList.map((interactionTemplate) => {
                        //This should be done in the constructure parser part , not hacky like this
                        let fieldElement = this.getSectionControlElementName(section.Description, interactionTemplate.validationRuleId);
                        let validatorFunction: IValidationRule = {
                            isActive: true,
                            mandatory: field.Mandatory,
                            sourceValue: '',
                            validationRuleId: interactionTemplate.validationRuleId,
                            validationRuleType: interactionTemplate.validationRuleType,
                            validators: [],
                            comparatorRuleId: interactionTemplate.comparatorRuleId,
                            comparatorValue: interactionTemplate.comparatorValue,
                            interactionTrue: interactionTemplate.interactionTrue,
                            interactionFalse: interactionTemplate.interactionFalse,
                            interactionElement: fieldElement
                        }
                        return validatorFunction;
                    });
                } else {
                    validationFunctions = [];
                }
                
                if(typeof(interactionFunctionList) !== 'undefined') {
                    field.ValidationRuleTemplateList = field.ValidationRuleTemplateList.concat(validationFunctions);
                }
            });
        });
        
    }


    sectionOrder(section:string): number{
        let order = 0;
        switch (section){
            case "General" : {
                order = 0;
                break;
            }
            case "Legal" : {
                order = 999;
                break;
            }
            default : {
                console.log("Section not found " + section +" Default Order set " + this.orderIndex);
                order = this.orderIndex;
                break;
            }
        }
        this.orderIndex = this.orderIndex + 1;
        return order;
    }

    hasSection(sectionName: string): boolean {
        let result = false;
        if (typeof(sectionName)  !== 'undefined') {
            const section = this.sectionList.find((formSection) => {
                return formSection.Description == sectionName;
            });

            if (typeof(section)  !== 'undefined') {
                result = true;
            }
        }
        return result;
    }
    getSection(sectionName: string) {
        let output: IFormSection | null = null;
        if (typeof(sectionName)  !== 'undefined') {
            const section = this.sectionList.find((formSection) => {
                return formSection.Description == sectionName;
            });

            if (typeof(section)  !== 'undefined') {
                output = section;
            }
            else {
                output = null;
            }
            return output;
        }
    }
    /**
     *  BF: This is a technical debt with the dynamic element ids
     * @param sectionName 
     * @param fieldName 
     * @returns 
     */
    getSectionControlElementName(sectionName: string, fieldName: string) {
        let fieldElement = '';
        let section = this.getSection(sectionName);
        if (section != null) {
            let field = section.Fields.find((field) => {
                return field.Atttribute === fieldName;
            });

            if (typeof(field) !== 'undefined'){

                let datepickerPrefix = '';
                if (field.Type == CRMControlType.DateTime) {
                    datepickerPrefix = 'dp-input-';
                }

                let fieldIndex = section.Fields.indexOf(field);
                if (fieldIndex > -1) {
                    
                    if (   sectionName === SectionDescription.Qualification  
                            || sectionName == SectionDescription.ProfessionalMemberships 
                            || sectionName == SectionDescription.ProfessionalBackgroundWorkExperience) {
                                // All other dynamic forms on a page
                               
                                fieldElement = `${datepickerPrefix}${fieldName}-modal-${fieldIndex}`;
                    }
                    else {
                        // All other forms on a page
                        fieldElement = `${datepickerPrefix}${fieldName}-control-${fieldIndex}`;
                
                    }
                }
            }
        }
        return fieldElement
    }

    lookupMatch(sectionDescription: SectionDescription, fieldAttribute: string, dropDownId: string) {
        let section = this.getSection(sectionDescription);
        if (section) {
            let field = section.Fields.find((field: IFormSubProgrammeField)=> {
                return field.Atttribute.toString() === fieldAttribute.toString();
            });
            if (field) {
                let value = field.DropDownOptions.find((lookup: IDropdownItem) => {
                    if (lookup.id === dropDownId) {
                        return lookup.id === dropDownId;
                    }
                });
                if (value) {
                    return value.itemText;
                } else {
                    return "Invalid ID";
                }
            }
        }
    }

    getFieldDropDownList(fieldAttributeId: string) {
        let list: Array<IDropdownItem> = [];
        let searchResult = this.fieldList.find((field) => {
            return field.Atttribute == fieldAttributeId;
        });
        if (typeof(searchResult) !== 'undefined') {
            list = searchResult.DropDownOptions;
        }
        return list;
    }

    getFieldConfig(fieldId: string): IFormSubProgrammeField | null {
        let field: IFormSubProgrammeField | null = null;
        let searchResult = this.fieldList.find((field) => {
            return field.FieldId == fieldId;
        });
        if (typeof(searchResult) !== 'undefined') {
            field = searchResult;
        }
        return field;
    }


    getLookupField(key: string) {
        let fields = this.getFieldConfig(key);
        
        return fields;
    }
}