import { z } from 'zod';
import {CRMEntity, CRMControlType, FormValidator} from '~/utils/enums';


export const SubProgrammeConfigFieldDropDownOption = z.object({
        id: z.string(),
        itemText:  z.string(),
        selected: z.boolean().optional().nullable()
});

export const SubProgrammeConfigFieldOptionQuery = z.object({
        Text: z.string().optional(),
        Value: z.string().optional()
});


export const SubProgrammeConfigFieldQuery = z.object({
        Attributes: z.string().nullable(),
        Atttribute: z.string(),
        EnteredValue: z.string().nullable(),
        Entity: z.nativeEnum(CRMEntity).optional(),
        Field: z.string(),
        Hint: z.string().nullable(),
        HtmlType: z.string().nullable(),
        Label: z.string(),
        Level: z.string(),
        Limit: z.number().nullable(),
        Order: z.number(),
        Receiver: z.string().nullable(),
        Section: z.string(),
        Sender: z.string().nullable(),
        Type: z.nativeEnum(CRMControlType).optional(),
        Validator: z.nativeEnum(FormValidator),
        ValidatorRules: z.string().nullable(),
        ReadOnly: z.boolean().optional().nullable(),  
        Options: z.array(SubProgrammeConfigFieldOptionQuery).nullable()
});

export const SubProgrammeConfigQuery = z.object({
        ProgrammeID: z.string().optional(),
        ProgrammeCode: z.string().optional(),
        ProgrammeName: z.string().optional(),
        FreeProgramme: z.boolean().optional().nullable(),
        isEasyForm: z.boolean().optional().nullable(),
        isCustomProgramme: z.boolean().optional().nullable(),
        FormID: z.string().optional(),
        FormLevel: z.string().optional().nullable(),
        ContactName: z.string().optional(),
        ContactEmail: z.string().optional(),
        fields: z.array(SubProgrammeConfigFieldQuery)
}).nullable();

export const FieldQuery = z.array(SubProgrammeConfigFieldQuery).nullable();